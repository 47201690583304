/* .App{
  display: flex;
  overflow-x: hidden;
  overflow-y: hidden;
  position: relative;
  background-color: red;
}
.App .sideBarCon{
  width: 8.5%;
}
.App .uCon{
  overflow-y: scroll;
}
.App .profileDrp{
  background-color: black;
  position: fixed;
  padding: 20px;
  right: .4%;
  top: 7%;
  display: flex;
  flex-direction: column;
  gap: .5rem;
  width: 20%;
  border-radius: 5px;
}
.App .profileDrp main{
  display: flex;
  gap: 1rem;
}
.App .profileDrp main img{
  width: 15px;
  object-fit: contain;
}
.App .profileDrp main a{
  text-decoration: none;
  color: white;
  font-weight: 600;
  font-size: 14px;
}
.App .profileDrp .signout{
  margin-top: 1rem;
}
.App .profileDrp .signout a{
  color: #923f3f;
}
.App .bodyCon{
  position: absolute;
  width: 91.5%;
  min-height: 100vh;
  right: 0;
    left: 80.5%;
    bottom: 0;
    overflow-y: auto;
    margin: auto;
  border: 1px solid red;
  background-color: #222736;
}
@media (max-width: 1100px){
 
  .App .profileDrp{
    width: 90%;
  }
  .userProfile article section:nth-child(2) .update button{
    margin-bottom: 1rem;
  }
} */
 .home{
  display: flex;
  /* width: 100%; */
  position: relative;
  overflow-x: hidden;
 }
 .home .profileDrp{
  background-color: black;
  position: fixed;
  padding: 20px;
  right: .4%;
  top: 9%;
  display: flex;
  flex-direction: column;
  gap: .5rem;
  width: 20%;
  border-radius: 5px;
  z-index: 50;
}
.home .profileDrp main{
  display: flex;
  gap: 1rem;
}
.home .profileDrp main img{
  width: 15px;
  object-fit: contain;
}
.home .profileDrp main a{
  text-decoration: none;
  color: white;
  font-weight: 600;
  font-size: 14px;
}
.home .profileDrp .signout{
  margin-top: 1rem;
}
.home .profileDrp .signout a{
  color: #923f3f;
}
 .home .sideBarCon{
  width: 8.5%;
  background-color: #101225;
  z-index: 40;
  position: relative;
}
 .home .bodyCon{
 width: 90%;
 position: relative;
 }
 @media (max-width: 1100px){
  .home .sideBarCon{
    width: 10%;
    background-color: #101225;
    z-index: 40;
    position: fixed;
    /* left: -100%; */
  }
  .home .profileDrp{
    width: 90%;
    top: 11%;
  }
   .home .bodyCon{
   width: 100%;
   margin: auto;
   position: relative;
   }
 }