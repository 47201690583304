.dashboard, .deposit, .withdraw, .tx, .referral,.support, .userProfile, .kyc, .setup, .connect, .upload, .compWith, .compWith2{
    color: white;
    display: flex;
    gap: 3rem;
    flex-direction: column;
    padding-inline: 2rem;
    padding-block: 2rem;
  margin-top: 4rem;
}
.dashboard *{
    margin: 0;
}
.dashboard{
    display: flex;
    flex-direction: column;
}
.dashboard .slides{
    display: flex;
    animation: slides 20s linear 0s infinite;
    z-index: 1;
}
@keyframes slides {
    100%{
        transform: translateX(-80%);
    }
}
.dashboard .slides span{
    min-width: 15%;
    font-weight: 800;
    font-size: 16px;
    display: flex;
    align-items: center;
}
.dashboard .slides span img{
    width: 30px;
}
.dashboard .bio{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: black;
    padding: 70px 50px;
    border-radius: 20px;
}
.dashboard h1{
    font-size: 30px;
    font-weight: bolder;
}
.dashboard p{
    width: 50%;
    font-size: 14px;
    line-height: 20px;
}
.dashboard button{
    width: fit-content;
    padding: 15px 50px;
    font-size: 18px;
    background-color: #f0475c;
    color: white;
    border-radius: 40px;
    border: 1px solid white;
    margin-top: 1rem;
}
.ioe{
    background-color: white;
    width: 100%;
}
.deposit h3, .withdraw h3, .tx h3, .referral h3, .support h3, .userProfile h3, .kyc h3, .setup h3, .connect h3, .upload h3, .compWith h3{
    margin: 0;
    padding: 0;
}
.deposit .mainCon{
    width: 35%;
}
.withdraw .mainCon, .compWith .mainCon{
    width: 40%;
    border-radius: 3px;
}
.deposit .depositmenu{
    position: fixed;
    margin: auto;
    width: 30%;
    font-size: 14px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    background-color: black;
    color: #b7cbd8;
    left: 0;
    right: 0;
    top: 8%;
    z-index: 42;
}
.deposit .depositmenu nav{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.deposit .depositmenu h6{
    font-size: 16px;
}
.deposit .depositmenu h6, .deposit .depositmenu p{
    margin: 0;
}
.deposit .depositmenu nav img{
    width: 20px;
}
.deposit .depositmenu .addd{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.deposit .depositmenu .addd p{
    border: 1px solid;
    padding: 10px;
    width: 100%;
}
.deposit .depositmenu .addd h4{
    border: 1px solid;
    padding: 10px;
}
.deposit .mainCon, .withdraw .mainCon, .compWith .mainCon{
    background-color: black;
    margin: auto;
    padding: 1.5rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;
}
.deposit .mainCon header h2, .withdraw .mainCon header h2{
    margin: 0;
    padding: 0;
}
.deposit .mainCon header p{
    margin: 0;
    padding: 0;
    padding-top: .5rem;
    font-size: 14px;
    color: #84a8be;
}
.deposit .mainCon article{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.deposit .mainCon article .currency{
    display: flex;
    gap: 20px;
    margin-left: 1rem;
}
.deposit .mainCon article .currency div{
    display: flex;
    gap: 4px;
    align-items: center;
    color: #d03840;
    /* border: 1px solid white; */
    /* box-shadow: 0px 7px 10px #3f82be; */
    padding: 1rem 1rem;
}
.deposit .mainCon article .currency img{
    width: 35px;
}
.deposit .mainCon article .addMinus{
    display: flex;
    margin-top: 1rem;
}
.deposit .mainCon article form{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.deposit .mainCon article form input{
    padding-block: 12px;
    border-radius: 5px;
    background-color: transparent;
    border: none;
    color: white;
    font-size: 16px;
    margin-bottom: 2rem;
    outline: none;
}
.deposit .mainCon article form label{
    font-weight: 700;
    font-size: 18px;
}
.deposit .mainCon article form button{
    padding-block: 12px;
    font-size: 18px;
    color: white;
    background-color: #f34758;
    border-radius: 8px;
}
.deposit .mainCon .bc{
    color: #f34758;
}
.deposit .mainCon .bc img{
    width: 15px;
}
.addMinus span{
    font-size: 14px;
    margin-inline: 10px;
    padding: 5px;
    border-radius: 3px;
}
.addMinus .plus:hover{
    background-color: gray;
}
.addMinus .minus{
    background-color: #f46969;
}
.foot{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-block: 20px;
    color: gray;
    position: fixed;
    width: 85%;
    bottom: 0;
}
.foot .chatSupport{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #f34758;
    padding: 10px;
}
.wdDiv, .cont{
    padding: 20px;
    display: flex;
    flex-direction: column;
    background-color: black;
    width: 30%;
    position: fixed;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 42;
}
.cont{
    color: white;
    align-items: center;
    width: 20%;
    text-align: center;
    gap: 1rem;
    background-color: #2e3446;
    border-radius: 3px;
}
.cont img{
    width: 70px;
}
.cont button{
    padding-block: 10px;
    font-size: 16px;
    color: white;
    background-color: #f34758;
    border-radius: 8px;
    margin-right: 10px;
    border: none;
}
.wdDiv input{
    background-color: transparent;
    color: white;
    border: 1px solid gray;
    padding: 10px 15px;
    margin: 10px;
}
.wdDiv button{
    padding-block: 10px;
    font-size: 16px;
    color: white;
    background-color: #f34758;
    border-radius: 8px;
    margin-top: 1rem;
}
.compWith .mainCon button{
    padding-block: 10px;
    padding-inline: 10px;
    border: none;
    font-size: 16px;
    color: white;
    background-color: #f34758;
    border-radius: 2px;
    margin-top: 1rem;
}
.withdraw .kyc-pop{
    width: 50%;
    padding: 30px;
    margin: auto;
    text-align: center;
    color: white;
    background-color: black;
    position: fixed;
    left: 0;
    right: 0;
    top: 25%;
}
.withdraw .kyc-pop h3{
    margin: 0;
    margin-bottom: 1rem;
}
.withdraw .kyc-pop button{
    padding-block: 12px;
    font-size: 16px;
    color: white;
    background-color: #f34758;
    border-radius: 8px;
    margin-top: 1rem;
    width: 70px;
    border: none;
}
.withdraw .mainCon article{
    /* border: 1px solid; */
    margin-top: -1rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}
.withdraw .mainCon article p{
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 20px;
    color: #b7cbd8;
}
.withdraw .mainCon article a{
    text-decoration: none;
    color: #d03840;
    font-weight: 600;
}
.withdraw .mainCon article .withdrawBtn{
    display: flex;
    gap: 4rem;
    margin-left: 4rem;
    margin-block: 4rem;
}
.withdraw .mainCon article .withdrawBtn img{
    width: 80px;
    padding: 5px;
    cursor: pointer;
}
.compWith .acctBalCon{
    background-color: white;
    color: black;
    padding-inline: 1.5rem;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    margin: auto;
    width: 40%;
    margin-bottom: 1rem;
}
.compWith form{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.compWith form small{
    color: #f0475c;
    text-align: center;
    font-size: 12px;
}
.compWith form div, .compWith2 form div{
    display: flex;
    flex-direction: column;
    gap: .5rem;
}
.compWith form div input{
    background-color: #2e3446;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    border: none;
    color: white;
}
.compWith form div select, .compWith2 form div select{
    background-color: #2e3446;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    border: none;
    color: white;
}
.compWith form div label{
    color: #a8c1da;
    font-weight: 600
}
.compWith2 section{
    background-color: black;
    width: 50%;
    margin: auto;
    padding: 20px;
}
.compWith2 nav{
    display: flex;
    justify-content: space-between;
}
.compWith2 h2{
    font-size: 16px;
}
.compWith2 p{
    color: #d03840;
}
.compWith2 article{
    background-color: rgb(160, 182, 160);
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
}
.compWith2 article div{
    display: flex;
    flex-direction: row;
    gap: 3rem;
}
.compWith2 article div p{
    color: green;
    font-weight: 900;
}
.compWith2 article div main span{
    background: black;
    display: flex;
    width: fit-content;
    align-items: center;
    border-radius: 5px;
}
.compWith2 article div main span small{
    padding: 5px 10px;
}
.compWith2 article div main span img{
    background-color: #d03840;
    padding: 5px;
    width: 20px;
}
.compWith2 article button{
    width: fit-content;
    padding: 10px;
    color:  rgb(224, 205, 98);
    border-color: rgb(204, 195, 145);
    background-color: transparent;
    border: 1px solid;
    border-radius: 5px;
    font-weight: 600;
    cursor: pointer;
}
.compWith2 .ssss{
    padding-block: 12px;
    font-size: 16px;
    color: white;
    background-color: #f34758;
    border-radius: 8px;
    margin-top: 1rem;
    width: 70px;
    border: none;
}
/* .withdraw .mainCon article .withdrawBtn img:hover{
    outline: 2px solid gray;
} */
.wallet{
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding-inline: 2rem;
    padding-top: 3rem;
    margin-top: 4rem;
}
.wallet .walletUp{
    display: flex;
    padding: 10px;
    gap: 3%;
}
.wallet .walletUp .walletUpL{
    display: flex;
    flex-direction: column;
    height: fit-content;
    gap: 1rem;
    /* border: 1px solid white; */
    width: 28%;
    padding: 50px 20px;
    padding-bottom: 5rem;
    margin-left: 2rem;
    background-color: black;
}
.wallet .walletUp .walletUpL main{
    display: flex;
}
.wallet .walletUp .walletUpL main article{
    width: 50%;
    display: flex;
    flex-direction: column;
    padding-inline: 2rem;
    gap: .4rem;
    color: white;
}
.wallet .walletUp h3, .wallet .walletUp h4{
    margin: 0;
    padding: 0;
}
.wallet .walletUp button{
    padding-block: 12px;
    font-size: 16px;
    color: white;
    background-color: #f34758;
    border-radius: 8px;
    margin-top: 1rem;
}
.wallet .walletUp .walletUpR{
    width: 68%;
    background-color: black;
    color: white;
}
.wallet .walletUp .walletUpR img{
    width: 100%;
    object-fit: contain;
    height: 100%;
}
.wallet .walletBtm{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    gap: 1rem;
    width: 99%;
}
.tx .txtab{
    display: flex;
    gap: 20px;
    cursor: pointer;
}
.tx .txtab .inactive{
    font-weight: 600;
    transition: 1s linear;
    margin: 0;
    border-radius: 5px;
    background-color: white;
    color: black;
    padding: 10px 30px;
}
.tx .txtab .active{
    font-weight: 600;
    transition: 1s linear;
    margin: 0;
    border-radius: 5px;
    background-color: #556ee6;
    color: white;
    padding: 10px 30px;
}
.referral .refBal{
    display: flex;
    gap: 1rem;
}
.referral .refLink{
    background-color: black;
    width: 35%;
    padding: 10px 20px;
    padding-top: 2rem;
    margin: auto;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.referral .refLink .intro{
    width: 90%;
    margin: 0;
    margin-top: .5rem;
}
.referral .refLink .linkkk{
    border: 1px solid;
    display: flex;
    align-items: center;
    /* padding: 0; */
    background-color: #f34758; 
    height: fit-content;
}
.referral .refLink .linkkk #link{
    /* border: 1px solid; */
    width: 80%;
    overflow-x: hidden;
    background-color: gray;
    height: 40px;
    padding-left: 10px;
    white-space: nowrap;
}
.referral .refLink .linkkk #link small{
    /* border: 1px solid; */
    word-break: keep-all;
    margin: 0;
}
.referral .refLink .linkkk #copy{
    text-align: center;
    width: 20%;
    height: initial;
    font-weight: 500;
}
.referral .socials{
    display: flex;
    gap: 1rem;
}
.referral .socials a{
    text-decoration: none;
    color: white;
    background-color: #f34758;
    padding: 12px;
    border-radius: 5px;
}
.referral .refHis{
    color: white;
    background-color: #f34758;
    padding: 12px;
    border-radius: 5px;
    width: fit-content;
}
.supportMain{
    background-color: black;
    color: white;
    width: 40%;
    margin: auto;
    padding: 20px 20px;
}
.supportMain form{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.supportMain form .subject{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    position: relative;
    margin-top: 1rem;
}
.supportMain form .subject label{
    font-size: 18px;
    margin-left: 1rem;
}
.supportMain form .subject select, .supportMain form input{
    padding: 10px 5px;
    border-radius: 5px;
    background-color: transparent;
    color: gray;
}
.supportMain form .subject select option{
    font-size: 14px;
    background-color: black;
}
.supportMain form .msgCon{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.supportMain form .msgCon textarea{
    height: 25vh;
    background-color: #2e3446;
    border: 5px;
    padding: 10px;
}
.supportMain form button{
    color: white;
    background-color: #f34758;
    padding: 12px 30px;
    border-radius: 5px;
    width: fit-content;
}
.userProfile article{
    display: flex;
    flex-direction: column;
    gap: 3rem;
    margin-top: -1rem;
}
.userProfile article section{
    background-color: black;
    padding: 10px 20px;
    border-radius: 10px;
}
.updateMessage{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    text-align: center;
}
.updateMessage h4{
    margin: 0;
    margin-bottom: -.5rem;
    color: gray;
    font-size: 22px;
}
.updateMessage button{
    color: white;
    background-color: #394aa0;
    padding: 10px;
    border: none;
    width: 100px;
}
.updateMessage img{
    width: 60px;
    border: 1px solid #84a8be;
    height: 60px;
    border-radius: 50%;
    padding: 10px;
}
.userProfile article section:nth-child(1){
    padding: 0;
}
.userProfile article section main:nth-child(1){
    padding: 10px 20px;
    background-color: #151c3a;
}
.userProfile article section main:nth-child(1) p{
    color: #394aa0;
}
.userProfile article section main:nth-child(1) span a{
    text-decoration: none;
    color: white;
    background-color: #f34758;
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 5px;
    width: fit-content;
}
.userProfile article section main:nth-child(2){
    padding: 10px 20px;
    padding-top: 1rem;
}
.userProfile article section main:nth-child(2) img{
    border: 1px solid;
    transform: translateY(-2rem);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 2px;
    margin-left: 1rem;
}
.userProfile article section:nth-child(2) .info{
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}
.userProfile article section:nth-child(2) .info ul{
    list-style: none;
    display: flex;
    margin-left: -2rem;
}
.userProfile article section:nth-child(2) .info ul li{
    width: calc(100%/2);
    font-weight: 500;
}
.userProfile article section:nth-child(2) .update button{
    margin-right: 1rem;
    color: white;
    background-color: #f34758;
    padding: 12px 30px;
    border-radius: 5px;
    width: fit-content;
    border: none;
}
.kyc article{
    background-color: black;
    width: 30%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-bottom: 5rem;
    border-radius: 5px;
}
.kyc article img{
    width: 400px;
}
.kyc article h3{
    margin-bottom: 1rem;
    font-weight: 600;
    font-size: 22px;
}
.kyc article p{
    margin: 0;
    font-size: 16px;
}
.kyc article #btt{
    margin-bottom: 2rem;
}
.kyc article a{
    text-decoration: none;
    color: white;
    background-color: #f34758;
    padding: 12px 30px;
    border-radius: 5px;
    width: fit-content;
    border: none;
}
.setup .mainCon{
    width: 45%;
    background-color: black;
    margin: auto;
    padding: 30px 20px;
}
.setup .mainCon h4{
    font-weight: 800;
    margin: 0;
}
.setup .mainCon p{
    font-size: 14px;
    line-height: 20px;
}
.setup .mainCon .table{
    display: flex;
    flex-direction: column;
}
.setup .mainCon .table ul:nth-child(1){
    font-weight: 800;
}
.setup .mainCon .table ul{
    display: flex;
    gap: 1rem;
    list-style: none;
    align-items: center;
}
.setup .mainCon .table ul li{
    width: 10%;
    font-size: 14px;
}
.setup .mainCon .table ul li:nth-child(1) img{
    width: 40px;
}
.setup .mainCon .table ul .inputAdd{
    width: 55%;
}
.setup .mainCon .table ul input{
    width: 80%;
    background-color: #2e3446;
    outline: none;
    border: none;
    border-radius: 2px;
    padding: 5px 15px;
    font-size: 14px;
}
.setup .mainCon .editBnk{
    margin-top: 3rem;
    padding-left: 2rem;
    display: flex;
    flex-direction: column;
    gap: 0rem;
}
.setup .mainCon .editBnk p{
    font-size: 14px;
    line-height: 20px;
    margin: 0;
}
.setup .mainCon .editBnk ul{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.setup .mainCon .editBnk ul a{
    text-decoration: none;
    color: inherit;
}
.setup .editss img{
    width: 20px;
}
.setup .editss{
    display: flex;
    align-items: center;
    gap: .5rem;
    cursor: pointer;
}
.setup .checkclose{
    display: flex;
    gap: 5px;
}
.setup .checkclose img{
    width: 20px;
    background-color: #d03840;
    padding: 5px;
    cursor: pointer;
    border-radius: 3px;
}
.bnkacctCon{
    position: fixed;
    background-color: black;
    padding: 20px;
    width: 40%;
    left: 30%;
}
.connect .mainCon{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}
.upload section{
    border: 1px solid white;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
}
.upload section button{
    text-decoration: none;
    color: white;
    background-color: #f34758;
    padding: 12px 30px;
    border-radius: 5px;
    width: fit-content;
    border: none;
}
.resetP{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    border-radius: 7px;
    color: white;
    padding-bottom: 7rem;
}
.resetP .resetLogo{
    transform: translateY(-5rem);
    width: 100px;
    height: 100px;
    object-fit: contain;
    border-radius: 50%;
    padding: 2px;
    margin-left: 1rem;
    margin-bottom: -6.6rem;
}
.resetP .inputCon{
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.resetP form{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-bottom: 4rem;
    background-color: black;
    border-radius: 7px;
}
.resetP form main{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 90%;
    margin: auto;
}
.resetP form main div{
    display: flex;
    flex-direction: column;
    gap: .5rem;
}
.resetP form main div input{
    background-color: #2e3446;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    border: none;
    width: 75%;
    color: white;
}
.resetP .eye{
    object-fit: contain;
}
.resetP form main div label{
    color: #a8c1da;
    font-weight: 600
}
.resetP form .sub{
    color: white;
    background-color: #f34758;
    padding: 10px;
    border-radius: 5px;
    width: 90%;
    margin: auto;
    border: none;
}
.resetP .resetForm{
    border-radius: 7px;
}
.resetHeader{
    display: flex;
    justify-content: space-between;
    background-color: #151c3a;
    padding: 20px 30px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}
.resetHeader h3{
    font-size: 18px;
    width: 40%;
}
.resetHeader img{
    width: 150px;
}    
.notistab{
    position: fixed;
    background-color: black;
    color: white;
    z-index: 40;
    padding: 15px 20px;
    right: .5%;
    top: 11%;
    width: 400px;
    display: flex;
    flex-direction: column;
}
.notistab nav{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
}
.notistab nav small{
    font-size: 12px;
    color: #d03840;
}
.notistab article{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.notistab article div{
    display: flex;
    align-items: flex-start;
    gap: 1rem;
}
.notistab article div h3,.notistab article div p{
    margin: 0;
    padding-bottom: 5px;
}
.notistab article div h3{
    font-size: 14px;
}
.notistab article div p{
    font-size: 12px;
}
.notistab article div img{
    width: 20px;
    height: 20px;
    border: 1px solid #2e3446;
    padding: 7px;
    border-radius: 50%;
    background-color: #2e3446;
    object-fit: contain;
}
.notistab b{
    font-weight: 600;
    text-decoration: none;
    color: #d03840;
    width: 100%;
    margin-top: 1rem;
    text-align: center;
    font-size: 12px;
}
@media (max-width: 1100px){
    .notistab{
        width: 85%;
        left: 2.5%;
        top: 13%;
    }
    .dashboard, .deposit, .withdraw, .tx, .referral,.support, .userProfile, .kyc, .setup, .connect, .upload, .wallet, .compWith, .compWith2{
        color: white;
        display: flex;
        gap: 3rem;
        flex-direction: column;
        padding-inline: 0rem;
        padding-block: 2rem;
      margin-top: 4rem;
    }
    .dashboard .bio{
        padding: 40px 30px;
    }
    .dashboard p{
        font-size: 14px;
        width: 100%;
    }
    .dashboard .slides span{
        min-width: 40%;
    }
    .deposit .mainCon{
        width: 80%;
    }
    .withdraw .mainCon{
        width: 80%;
    }
    .deposit .depositmenu .addd{
        width: 100%;
    }
    .deposit .depositmenu .addd p{ 
        width: 70%;
        overflow-x: hidden;
    }
    .deposit .mainCon article form button{
        font-size: 14px;
    }
    .withdraw .kyc-pop{
        width: 70%;
    }
    .withdraw .mainCon article .withdrawBtn img{
        width: 50px;
    }
    .withdraw .mainCon article .withdrawBtn{
        margin-left: 2rem;
    }
    .withdraw .mainCon article p{
        font-size: 12px;
    }
    .compWith .mainCon{
        width: 90%;
        margin-left: -15px;
    }
    .compWith .acctBalCon{
        width: 90%;
        margin-left: -10px;
    }
    .compWith2 section{
        width: 90%;
        margin-left: -1rem;
    }
    .cont{
        width: 80%;
    }
    .wallet .walletUp{
        flex-direction: column;
        gap: 2rem;
    }
    .wallet .walletUp .walletUpL{
        width: 90%;
        margin-left: 0;
    }
    .wallet .walletUp .walletUpL main article{
        width: 100%;
    }
    .wallet .walletUp .walletUpR{
        width: 100%;
        height: 30vh;
        background-color: black;
        color: white;
    }.tx .txtab{
        flex-direction: column;
    }
    .tx .txtab .inactive{
        width: fit-content;
    }
    .tx .txtab .active{
        width: fit-content;
    }
    .userProfile article section:nth-child(2) .update button{
        margin-bottom: 1rem;
    }
    .depHis main ul li:nth-child(1){
        width: 50%;
    }
    .depHis main ul li:nth-child(1),  .depHis main ul li:nth-child(4),  .depHis main ul li:nth-child(5),  .depHis main ul li:nth-child(6){
        display: none;
    }
    .referral .refBal{
        flex-direction: column;
    }
    .referral .refLink{
        width: 90%;
    }
    .referral .socials{
        flex-wrap: wrap;
    }
    .referral .socials a{
        font-size: 12px;
    }
    .referral .refHis{
        font-size: 12px;
    }
    .supportMain{
        width: 90%;
    }
    .setup .mainCon{
        width: 90%;
    }
    .setup .mainCon .table ul{
        margin-left: -1rem;
    }
    .setup .mainCon .table ul li{
        width: 30%;
        font-size: 14px;
    }
    .setup .mainCon .table ul li:nth-child(1) img{
        width: 20px;
    }
    .setup .mainCon .table ul .inputAdd{
        width: 30%;
    }
    .kyc article{
        width: 100%;
    }
    .kyc article img{
        width: 200px;
    }
    .kyc article h3{
        margin-bottom: 1rem;
        font-weight: 600;
        font-size: 18px;
    }
    .kyc article p{
        margin: 0;
        font-size: 12px;
    }
    .kyc button{
        font-size: 12px;
    }
    .bnkacctCon{
        position: fixed;
        background-color: black;
        padding: 20px;
        width: 80%;
        left: 5%;
    }
    .wdDiv{
        width: 80%;
    }
    .deposit .depositmenu{
        width: 80%;
    }
    
}