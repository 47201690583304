.login{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: black;
}
.login section{
    width: 40%;
    margin: auto;
    background-color: #1d2b3a;
    color: white;
    padding: 45px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}
.login h1{
    font-size: 35px;
}
.login form{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
}
.login form .inputConb, .register form .inputConb{
    display: flex;
    flex-direction: row;
    width: 100%;
}
.login form div{
    display: flex;
    flex-direction: column;
    gap: .5rem;
    align-items: center;
    justify-content: center;
}
.login form div input{
    width: 90%;
    outline: none;
    border: 1px solid black;
    background-color: transparent;
    padding: 10px;
    color: white;
}
.login a{
    text-decoration: none;
    color: inherit;
    text-align: center;
    font-size: 12px;
}
.login button{
    color: white;
    width: 95%;
    margin: auto;
    background-color: #f34758;
    padding: 10px;
    border-radius: 5px;
    border: none;
}
.login label{
    font-size: 12px;
}

.login small{
    text-align: center;
}
.register{
    display: flex;
    background-color: #0a0e14;
    width: 100%;
    min-height: 100vh;
}
.register h4{
    color: white;
}
.register p{
    color: gray;
}
.register h4, .register p{
    font-size: 12px;
}
.register .left{
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: auto;
    margin-bottom: 5rem;
}
.register .right{
    width: 40%;
    background-color: black;
    color: white;
    display: flex;
    flex-direction: column;
    padding: 30px 50px;
    gap: 3rem;
    /* align-items: center; */
}
.register .right main{
    display: flex;
    align-items: center;
    gap: 4rem;
    text-align: center;
}
.register .right main img{
    width: 70px;
}
.register .right main h1{
    font-size: 30px;
    font-weight: 800;
}
.register .right main h4{
    font-size: 20px;
}
.register .right form{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.register .right form div{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.register input{
    background-color: black;
    padding: 10px;
    border: none;
    width: 100%;
    color: white;
}
.register form button{
    color: white;
    width: 100%;
    margin: auto;
    background-color: #fdab2b;
    padding: 10px;
    border-radius: 5px;
    border: none;
}
.register small{
    text-align: center;
}
.register small a{
    text-decoration: none;
    color: rgb(50, 50, 253);
}
.register .agree{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
}

@media (max-width: 1100px) {
    .login{
        height: 100vh;
    }
    .login section{
        width: 60%;
    }
    .login h1, .login h2{
        font-size: 20px;
    }
    .register{
        flex-direction: column;
        width: 100%;
        height: fit-content;
        padding-block: 3rem;
    }
    .register .left{
        width: 100%;
    }
    .register .left img{
        display: none;
    }
    .register .right{
        width: 100%;
        padding: 0px;
    }
    .register .right form{
        width: 90%;
        margin: 10px;
    }
    .register .right main{
        flex-direction: column;
    }
    .register .right main h1{
        font-size: 20px;
        font-weight: 800;
    }
    .register .right main h4{
        font-size: 16px;
    }
}