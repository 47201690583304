.sidebar{
    height: 100vh;
    display: flex;
    flex-direction: column;
    position: fixed;
    background-color: #101225;
}
.sidebar .top{
    background-color: #101225;
    display: flex;
    justify-content: center;
    padding-block: 1.5rem;
}
.sidebar .top img{
    width: 50px; 
}
.sidebar .btm{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0;
    border: 1px solid black;
    color: #f7fcf4;
}
.sidebar .btm span{
    background-color: #2e3547;
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
}
.sidebar .btm span p{
    font-size: 12px;
    color: #666670;
}
.sidebar section{
    /* border: 1px solid white; */
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}
.sidebar section div{
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;
    text-align: center;
}
.sidebar section div img{
    padding: 0;
    margin: 0;
    width: 16px;
}
.sidebar section div .active{
    color: white;
    opacity: 1;
    /* background-color: white; */
}
.sidebar section div a{
    opacity: .2;
}
.sidebar section div p{
    margin: 0;
    padding: 0;
    opacity: .8;
    font-size: 13px;
    width: 80%;
    font-weight: 700;
}
.topnav{
    display: flex;
    justify-content: space-between;
    padding-inline: 1rem;
    padding-left: 2rem;
    padding-block: .5rem;
    align-items: center;
    background-color: #101225;
    position: fixed;
    right: 0;
    top: 0;
    margin: auto;
    z-index: 30;
    width: 90%;
}
.topnav .profileCon{
    display: flex;
    align-items: center;
    gap: 1.5rem;
}
.topnav .profileCon .notisCon{
    color: white;
    position: relative;
}
.topnav .profileCon .notisCon small{
    position: absolute;
    top: -20%;
    left: 70%;
    background-color: red;
    border-radius: 50%;
    height: 15px;
    width: 15px;
    font-size: 10px;
    padding: 0;
    margin: 0;
    text-align: center;
}
.topnav .profileCon span{
    display: flex;
    align-items: center;
    gap: 10px;
    color: white;
    font-weight: 600;
    padding: 10px 20px;
}
.topnav .profileCon span p{
    font-size: 12px;
}
.topnav .profileCon span .pfp{
    border: 1px solid gray;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    padding: 1px;
    outline: 2px solid gray;
}
.topnav .profileCon span .arrd{
    width: 15px;
}
.topnav .notisBtn{
    animation: dingle 1s linear 0s infinite;
}
@keyframes dingle {
    form{
        transform: translateX(3px);
    }
    to{
        transform: translateX(0);
    }
}
.balance{
    color: white;
    /* border: 1px solid; */
    margin-block: .5rem;
    width: 28.5%;
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    padding: 30px 20px;
    border-radius: 15px;
    background-color: #000;
}
.balance h4, .balance h3{
    margin: 0;
    padding: 0;
}
.balance h4{
    color: #666670;
}
.depHis{
    /* border: 1px solid; */
    background-color: black;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.depHis h2{
    margin: 0;
}
.depHis main .header{
    margin-left: -1rem;
    list-style: none;
    display: flex;
    /* justify-content: space-between; */
    font-size: 16px;
    font-weight: 800;
    margin-bottom: 2rem;
}
.depHis main{
    display: flex;
    flex-direction: column;
}
.depHis main .others{
    margin-left: -.9rem;
    list-style: none;
    display: flex;
    /* justify-content: space-between; */
    font-size: 15px;
}
.depHis main ul li{
    /* border: 1px solid; */
    width: calc(100%/4);
    color: #a0a2b9;
}
.updateProfile{
    position: fixed;
    background-color: black;
    z-index: 40;
    color: white;
    width: 25%;
    overflow-y: scroll;
    height: 100vh;
    transition: 2s ease-in;
    z-index: 42;
}
.updateProfile header{
    position: relative;
}
.updateProfile nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: .5rem;
}
.updateProfile form{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-bottom: 4rem;
    margin-top: 2rem;
    color: white;
}
.updateProfile form .pfpCon{
    display: flex;
    flex-direction: column;
    gap: 2px;
    text-align: center;
    align-items: center;
    color: #f34758;
}
.updateProfile form .pfpCon img{
    width: 100px;
    border: 1px solid gray;
}
.updateProfile form .pfpCon button{
    color: white;
    background-color: #f34758;
    padding: 10px;
    border-radius: 5px;
    width: fit-content;
    border: none;
}
.updateProfile form main{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 90%;
    margin: auto;
}
.updateProfile form main div{
    display: flex;
    flex-direction: column;
    gap: .5rem;
}
.updateProfile form main div input{
    background-color: #2e3446;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    border: none;
    color: white;
}
.updateProfile form main div select{
    background-color: #2e3446;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    border: none;
    color: white;
}
.updateProfile form main div label{
    color: #a8c1da;
    font-weight: 600
}
.updateProfile form main div textarea{
    background-color: #2e3446;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    border: none;
}
.updateProfile form .sub{
    color: white;
    background-color: #f34758;
    padding: 10px;
    border-radius: 5px;
    width: 90%;
    margin: auto;
    border: none;
}
.bankAcct{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.bankAcct header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.bankAcct header h3{
    margin: 0;
}
.bankAcct header img{
    width: 25px;
}
.bankAcct form{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.bankAcct form div{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.bankAcct form div input{
    background-color: #2e3547;
    border: none;
    outline: none;
    padding: 15px 10px;
    border-radius: 3px;
}
.bankAcct form div label{
    font-weight: 800;
}
.bankAcct form button{
    color: white;
    background-color: #f34758;
    padding: 10px;
    border-radius: 5px;
    width: fit-content;
    margin-left: auto;
    border: none;
}
.eachwallet{
    width: 28%;
    background-color: black;
    display: flex;
    gap: 2rem;
    padding: 20px;
}
.eachwallet main{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}
.eachwallet main img{
    width: 50px;
}
.eachwallet form{
    width: 65%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-weight: 600;
}
.eachwallet form textarea{
    background-color: #2e3547;
    border: none;
    outline: none;
    height: 70px;
}
.eachwallet form button{
    color: white;
    background-color: #f34758;
    border: none;
    padding-block: 8px;
    border-radius: 3px;
}
.eachwallet p{
    font-size: 14px;
    font-weight: 800;
    margin: 0;
}
.eachwallet small{
    font-size: 10px;
    background-color: #f34758;
    padding: 5px;
    border-radius: 10px;
}
@media (max-width: 1100px) {
    .sidebar{
        /* display: none; */
        position: fixed;
        width: 30%;
    }
    .topnav{
        width: 100%;
        left: 0;
    }
    .balance{
        width: 80%;
    }
    .updateProfile{
        width: 100%;
    }
    .eachwallet{
        width: 100%;
    }
    .bankAcct{
        width: 100%;
    }
    .foot{
        font-size: 14px;
    }
}