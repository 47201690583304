/* imageUpload.css */
.upload {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #101225;
  color: #fff; /* Light color for text on dark background */
  margin-bottom: 20px;
}

.upload h3 {
  color: #fff;
  text-align: center;
  margin-bottom: 20px;
}

.kyc-description {
  text-align: center;
  margin: auto;
  width: 80%;
  color: #ccc;
  margin-bottom: 20px;
}

.mainCon {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #222736 !important;
}

.input-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;
  max-width: 500px; /* Limit width for better appearance on larger screens */
}

.input-group label {
  margin-right: 10px;
  width: 150px;
  text-align: right;
  color: #ccc;
}

.input-group input,
.input-group select {
  padding: 10px;
  border: 1px solid #444;
  background-color: #101225;
  border-radius: 4px;
  flex: 1;
  color: #444;
}

.input-group input[type="date"] {
  max-width: 200px;
}

.custom-file-upload {
  display: inline-block;
  padding: 10px 20px;
  background-color: #cf2317;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 20px;
}

.custom-file-upload:hover {
  background-color: #45a049;
}

.custom-file-upload:active {
  background-color: #3e8e41;
}

.image-preview-container {
  border: 1px solid #444;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 600px;
  overflow: auto;
}

#imgPrv {
  width: 700px;
  max-width: 100%;
  margin-bottom: 15px;
}

@media (max-width: 1100px) {
  #imgPrv {
      width: 300px;
  }
}

.upload-button {
  padding: 12px 24px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 1rem;
}

.upload-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  color: #666;
}

.upload-button:hover {
  background-color: #0056b3;
}

.upload-button:focus {
  outline: none;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.error {
  color: #f44336;
  font-size: 0.9rem;
  margin-top: 5px;
  display: block;
}
.success-message {
  color: #4caf50; /* Green color for success */
  font-size: 1.1rem;
  margin-top: 15px;
  text-align: center;
}

.kyc-submitted{
text-align: center;
margin-top: 40px;
font-size: 1.2rem;
color: #aaa;
}